.error-component {
  height: calc(100vh - 500px);
}
@media (max-width: 768px) {
  .error-component {
    height: calc(100vh - 200px);
  }
}
.error-component__container {
  flex-direction: column;
}
.error-component__title {
  font-size: 80px;
  padding: 32px 16px 0px;
  display: block;
}
@media (max-width: 768px) {
  .error-component__title {
    font-size: 64px;
  }
}
@media (max-width: 480px) {
  .error-component__title {
    font-size: 48px;
    padding: 16px 8px 0px;
  }
}
.error-component__subtitle {
  font-size: 40px;
  padding: 32px 16px;
  display: block;
  color: #5b5c5d;
}
@media (max-width: 768px) {
  .error-component__subtitle {
    font-size: 32px;
  }
}
@media (max-width: 480px) {
  .error-component__subtitle {
    font-size: 24px;
    padding: 16px 8px;
  }
}
.error-component__reset-button {
  max-width: 200px;
}
