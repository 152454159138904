.qesheq-web-selector .article-main {
  max-width: 930px;
  width: 100%;
}
@media (max-width: 950px) {
  .qesheq-web-selector .article-main {
    max-width: auto;
  }
}
.qesheq-web-selector .article-main__media-container {
  display: grid;
  grid-template-columns: 46px 1fr;
  width: 100%;
  position: relative;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-main__media-container {
    display: block;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-main__share-list {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    z-index: 1;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    -webkit-transition: transform 0.7s;
    -moz-transition: transform 0.7s;
    -ms-transition: transform 0.7s;
    -o-transition: transform 0.7s;
  }
  .qesheq-web-selector .article-main__share-list--visible {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
.qesheq-web-selector .article-main__share-item {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}
.qesheq-web-selector .article-main__share-item:nth-last-child(2),
.qesheq-web-selector .article-main__share-item:nth-last-child(2) ~ .article-main__share-item {
  height: calc(100% / 2);
}
.qesheq-web-selector .article-main__share-item:nth-last-child(3),
.qesheq-web-selector .article-main__share-item:nth-last-child(3) ~ .article-main__share-item {
  height: calc(100% / 3);
}
.qesheq-web-selector .article-main__share-item:nth-last-child(4),
.qesheq-web-selector .article-main__share-item:nth-last-child(4) ~ .article-main__share-item {
  height: calc(100% / 4);
}
.qesheq-web-selector .article-main__share-item:nth-last-child(5),
.qesheq-web-selector .article-main__share-item:nth-last-child(5) ~ .article-main__share-item {
  height: calc(100% / 5);
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-main__share-item {
    width: 100%;
    height: 100% !important;
  }
  .qesheq-web-selector .article-main__share-item:nth-last-child(2),
  .qesheq-web-selector .article-main__share-item:nth-last-child(2) ~ .article-main__share-item {
    width: calc(100% / 2);
  }
  .qesheq-web-selector .article-main__share-item:nth-last-child(3),
  .qesheq-web-selector .article-main__share-item:nth-last-child(3) ~ .article-main__share-item {
    width: calc(100% / 3);
  }
  .qesheq-web-selector .article-main__share-item:nth-last-child(4),
  .qesheq-web-selector .article-main__share-item:nth-last-child(4) ~ .article-main__share-item {
    width: calc(100% / 4);
  }
  .qesheq-web-selector .article-main__share-item:nth-last-child(5),
  .qesheq-web-selector .article-main__share-item:nth-last-child(5) ~ .article-main__share-item {
    width: calc(100% / 5);
  }
}
.qesheq-web-selector .article-main__share-icon {
  color: white;
  font-size: 20px !important;
}
.qesheq-web-selector .article-main__share-facebook {
  background: #3b5998;
}
.qesheq-web-selector .article-main__share-twitter {
  background: #00aced;
}
.qesheq-web-selector .article-main__share-linkedin {
  background: #0077b5;
}
.qesheq-web-selector .article-main__share-whatsapp {
  background: #1db610;
}
.qesheq-web-selector .article-main__share-email {
  background: #797979;
}
.qesheq-web-selector .article-main__frame {
  width: 100%;
  aspect-ratio: 215/100;
}
@media (max-width: 1080px) {
  .qesheq-web-selector .article-main__frame {
    aspect-ratio: auto;
    height: 312px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-main__frame {
    min-height: 200px;
    height: calc((100vw - 16px) * 0.465);
  }
}
.qesheq-web-selector .article-main__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.qesheq-web-selector .article-main__description {
  font-size: 17px;
  font-family: Tajawal, sans-serif;
  font-weight: 500;
  color: #545454;
  line-height: 36px;
  padding: 50px 2px 20px 0;
}
.qesheq-web-selector .article-main__description-skeleton-container {
  display: block;
}
.qesheq-web-selector .article-main__description-skeleton-container:nth-of-type(1) {
  width: calc(100% - 300px);
}
.qesheq-web-selector .article-main__description-skeleton-container:nth-of-type(2) {
  margin-top: 40px;
}
@media (max-width: 600px) {
  .qesheq-web-selector .article-main__description-skeleton-container {
    width: 100% !important;
  }
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(1) {
  width: 90%;
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(2) {
  width: 100%;
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(3) {
  width: 70%;
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(4) {
  width: 80%;
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(5) {
  width: 60%;
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(6) {
  width: 85%;
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(7) {
  width: 90%;
}
.qesheq-web-selector .article-main__description-skeleton:nth-of-type(8) {
  width: 60%;
}
.qesheq-web-selector .article-main__editor-ad-wrapper {
  margin: 0 auto 30px;
  width: 320px;
}
@media (min-width: 550px) {
  .qesheq-web-selector .article-main__editor-ad-wrapper {
    width: 468px;
  }
}
@media (min-width: 1001px) {
  .qesheq-web-selector .article-main__editor-ad-wrapper {
    width: 300px;
    float: left;
    margin: 0 25px 15px 0;
  }
}
.qesheq-web-selector .article-main__editor-ad {
  width: 320px;
  height: 100px;
  margin: auto;
  overflow: hidden;
}
@media (min-width: 550px) {
  .qesheq-web-selector .article-main__editor-ad {
    width: 468px;
    height: 60px;
  }
}
@media (min-width: 1001px) {
  .qesheq-web-selector .article-main__editor-ad {
    width: 300px;
    height: 250px;
    margin-right: auto;
  }
}
.qesheq-web-selector .article-main__q-editor .q-editor-editable {
  font-size: 17px;
  line-height: 36px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-main__q-editor .q-editor-editable {
    font-size: 15px;
    line-height: 32px;
  }
}
.qesheq-web-selector .article-main__tags-list {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 50px;
}
.qesheq-web-selector .article-main__tag-item {
  border: 1px solid #d3d3d3;
  background: #fff;
  border-radius: 18px;
  margin: 0 0 5px 10px;
  font-size: 15px;
  color: #707070;
  padding: 5px 17px 4px;
}
