.qesheq-web-selector .header-layout {
  height: 100%;
  padding-top: 82px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout {
    padding-top: 65px;
  }
}
.qesheq-web-selector .header-layout__container {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
.qesheq-web-selector .header-layout__header {
  height: 82px;
  background: #1e1f20;
  color: #fff;
  position: fixed;
  width: 100%;
  font-size: 18px;
  top: 0;
  z-index: 20;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__header {
    height: 65px;
  }
}
.qesheq-web-selector .header-layout__right-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  max-width: calc(100% - 30px);
  height: 100%;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__right-content {
    max-width: 100%;
    justify-content: space-around;
  }
}
.qesheq-web-selector .header-layout__logo-container {
  width: 90px;
  display: block;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__logo-container {
    width: 75px;
  }
}
.qesheq-web-selector .header-layout__logo {
  width: 100%;
}
.qesheq-web-selector .header-layout__navigation-container {
  display: flex;
  padding: 0 30px 0 10px;
  height: 100%;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__navigation-container {
    position: fixed;
    right: 0;
    top: 0;
    background: #1e1f20;
    width: 245px;
    display: block;
    transform: translateX(100%);
    padding: 20px 0 20px 32px;
    transition: transform 0.5s;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__navigation-container--open {
    transform: translateX(0);
  }
}
.qesheq-web-selector .header-layout__scoll-wrapper {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__scoll-wrapper {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
.qesheq-web-selector .header-layout__navigation-list {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 105px);
  height: 100%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__navigation-list {
    display: block;
    height: auto;
    max-width: 100%;
    min-height: calc(100% - 215px);
    overflow-y: auto;
    padding: 10px 0;
  }
}
.qesheq-web-selector .header-layout__navigation-more {
  width: 105px;
  height: 100%;
  position: relative;
  padding: 0 28px 0 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__navigation-more {
    display: none;
  }
}
@media (min-width: 769px) {
  .qesheq-web-selector .header-layout__navigation-more:hover {
    background: rgba(255, 255, 255, 0.3);
  }
}
.qesheq-web-selector .header-layout__navigation-more-icon {
  --bg-color: white;
  position: absolute;
  left: 19px;
  top: calc(50% - 6px);
  font-size: 12px;
}
.qesheq-web-selector .header-layout__navigation-more-icon::before,
.qesheq-web-selector .header-layout__navigation-more-icon::after {
  border-radius: 2px;
}
.qesheq-web-selector .header-layout__navigation-more-icon::before {
  height: 4px;
  top: calc(50% - 2px);
}
.qesheq-web-selector .header-layout__navigation-more-icon::after {
  width: 4px;
  right: calc(50% - 2px);
}
.qesheq-web-selector .header-layout__nav-item {
  height: 100%;
}
@media (min-width: 769px) {
  .qesheq-web-selector .header-layout__nav-item:not(.header-layout__nav-item--in-header) {
    display: none;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__nav-item {
    height: auto;
    padding: 11px 50px 11px 0;
  }
}
.qesheq-web-selector .header-layout__nav-item-skeleton {
  width: 60px;
  display: block;
}
.qesheq-web-selector .header-layout__nav-item-skeleton .react-loading-skeleton {
  --base-color: #323335;
  --highlight-color: #5b5c5d;
}
.qesheq-web-selector .header-layout__nav-link {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 28px;
  transition: background 0.2s;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__nav-link {
    height: auto;
    padding: 0;
    font-size: 20px;
  }
}
@media (min-width: 769px) {
  .qesheq-web-selector .header-layout__nav-link:hover {
    background: rgba(255, 255, 255, 0.3);
  }
}
.qesheq-web-selector .header-layout__extended-navigation {
  display: none;
  height: 215px;
  margin-right: 47px;
  width: 100%;
  max-width: calc(100% - 80px);
  padding: 21px 0 25px;
  border-top: 1px solid rgba(222, 222, 222, 0.39);
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__extended-navigation {
    display: block;
  }
}
.qesheq-web-selector .header-layout__extended-navigation .header-layout__nav-item {
  display: block !important;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__extended-navigation .header-layout__nav-item {
    padding: 5px 0;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__extended-navigation .header-layout__nav-link {
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
  }
}
.qesheq-web-selector .header-layout__overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__overlay--visible {
    opacity: 1;
    pointer-events: fill;
  }
}
.qesheq-web-selector .header-layout__left-content {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__left-content {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.qesheq-web-selector .header-layout__search-icon {
  color: white;
}
.qesheq-web-selector .header-layout__search-icon svg {
  font-size: 24px;
}
.qesheq-web-selector .header-layout__burger-menu {
  color: white;
  display: none;
  position: absolute;
  height: 28px;
  width: 28px;
  right: 3px;
  top: calc(50% - 14px);
  cursor: pointer;
}
.qesheq-web-selector .header-layout__burger-menu svg {
  font-size: 28px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__burger-menu {
    display: block;
  }
}
.qesheq-web-selector .header-layout__close-button {
  display: none;
  position: absolute;
  left: 12px;
  top: 20px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .header-layout__close-button {
    display: block;
  }
}
.qesheq-web-selector .header-layout__close-icon {
  --bg-color: white;
}
