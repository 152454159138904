.qesheq-web-selector .featured-blocks__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 730px) {
  .qesheq-web-selector .featured-blocks__container {
    display: block;
  }
}
.qesheq-web-selector .featured-blocks__article {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 50%;
  position: relative;
}
@media (max-width: 730px) {
  .qesheq-web-selector .featured-blocks__article {
    width: 100%;
  }
}
.qesheq-web-selector .featured-blocks__frame {
  position: relative;
  max-height: 464px;
  height: calc((50vw - 20px) * 0.6544);
  width: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .qesheq-web-selector .featured-blocks__frame {
    height: calc((50vw - 10px) * 0.6544);
  }
}
@media (max-width: 730px) {
  .qesheq-web-selector .featured-blocks__frame {
    height: calc((100vw - 20px) * 0.654);
  }
}
.qesheq-web-selector .featured-blocks__frame::before {
  content: '';
  width: 101%;
  height: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.8)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
.qesheq-web-selector .featured-blocks__frame img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.qesheq-web-selector .featured-blocks__article-content {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding-bottom: 28px;
  align-items: center;
}
@media (max-width: 1000px) {
  .qesheq-web-selector .featured-blocks__article-content {
    padding-bottom: 24px;
  }
}
@media (max-width: 830px) {
  .qesheq-web-selector .featured-blocks__article-content {
    padding-bottom: 20px;
  }
}
.qesheq-web-selector .featured-blocks__article-label {
  font-size: 28px;
  background: rgba(230, 26, 50, 0.85);
  padding: 16px 12px 12px;
  min-width: 110px;
  text-align: center;
}
@media (max-width: 1000px) {
  .qesheq-web-selector .featured-blocks__article-label {
    font-size: 24px;
    min-width: 100px;
    padding: 12px 8px 8px;
  }
}
@media (max-width: 830px) {
  .qesheq-web-selector .featured-blocks__article-label {
    font-size: 22px;
    min-width: 90px;
    padding: 8px 8px 4px;
  }
}
@media (max-width: 730px) {
  .qesheq-web-selector .featured-blocks__article-label {
    font-size: 18px;
    min-width: 80px;
    padding: 8px 7px 6px;
  }
}
.qesheq-web-selector .featured-blocks__article-title {
  color: #fff;
  font-size: 30px;
  line-height: 39px;
  padding: 0px 16px 0px 12px;
  max-width: 390px;
}
@media (max-width: 1000px) {
  .qesheq-web-selector .featured-blocks__article-title {
    font-size: 26px;
    line-height: 32.5px;
  }
}
@media (max-width: 830px) {
  .qesheq-web-selector .featured-blocks__article-title {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 730px) {
  .qesheq-web-selector .featured-blocks__article-title {
    font-size: 22px;
    line-height: 27.5px;
  }
}
