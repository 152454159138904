.qesheq-web-selector .writers-listing {
  padding: 44px 0px 0px;
}
.qesheq-web-selector .writers-listing__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 1000px) {
  .qesheq-web-selector .writers-listing__container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 650px) {
  .qesheq-web-selector .writers-listing__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .qesheq-web-selector .writers-listing__container {
    display: block;
  }
}
.qesheq-web-selector .writers-listing__writer {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 52px 20px 68px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .qesheq-web-selector .writers-listing__writer {
    padding: 40px 20px 55px;
    padding: 40px 20px 56px;
  }
}
@media (min-width: 1001px) {
  .qesheq-web-selector .writers-listing__writer:nth-of-type(4n) {
    border-left: none;
  }
}
@media (min-width: 651px) and (max-width: 1000px) {
  .qesheq-web-selector .writers-listing__writer:nth-of-type(3n) {
    border-left: none;
  }
}
@media (min-width: 481px) and (max-width: 650px) {
  .qesheq-web-selector .writers-listing__writer:nth-of-type(2n) {
    border-left: none;
  }
}
@media (max-width: 480px) {
  .qesheq-web-selector .writers-listing__writer {
    border-left: none;
    border-right: none;
  }
}
.qesheq-web-selector .writers-listing__writer-frame {
  display: block;
  max-width: 242px;
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  margin: auto;
}
.qesheq-web-selector .writers-listing__writer-frame::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100%;
  border: 10px solid white;
  box-sizing: border-box;
}
.qesheq-web-selector .writers-listing__writer-image {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  object-fit: cover;
  border-radius: 100%;
  margin: 2px;
}
.qesheq-web-selector .writers-listing__writer-fullname {
  font-size: 26px;
  padding-top: 20px;
  display: block;
  text-align: center;
  color: #000;
}
@media (max-width: 650px) {
  .qesheq-web-selector .writers-listing__writer-fullname {
    font-size: 12px;
  }
}
.qesheq-web-selector .writers-listing__writer-articles-count {
  color: #fff;
  background: #e61a32;
  padding: 4px 10px 2px;
  font-style: italic;
  font-size: 16px;
  margin: 7px auto;
}
@media (max-width: 650px) {
  .qesheq-web-selector .writers-listing__writer-articles-count {
    font-size: 14px;
  }
}
