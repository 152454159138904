.qesheq-web-selector .articles-listing__title {
  font-size: 44px;
  line-height: 52.8px;
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
@media (max-width: 768px) {
  .qesheq-web-selector .articles-listing__title {
    font-size: 34px;
    line-height: 37.978px;
  }
}
.qesheq-web-selector .articles-listing__container {
  display: grid;
  grid-template-columns: calc((100% / 3) - 20px) calc((100% / 3) + 40px) calc((100% / 3) - 20px);
}
@media (max-width: 1460px) {
  .qesheq-web-selector .articles-listing__container {
    grid-template-columns: calc((100% / 3) - 5px) calc((100% / 3) + 10px) calc((100% / 3) - 5px);
  }
}
@media (max-width: 900px) {
  .qesheq-web-selector .articles-listing__container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 535px) {
  .qesheq-web-selector .articles-listing__container {
    display: block;
  }
}
.qesheq-web-selector .articles-listing__article {
  padding: 36px 20px 20px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.qesheq-web-selector .articles-listing__article:nth-of-type(1),
.qesheq-web-selector .articles-listing__article:nth-of-type(2),
.qesheq-web-selector .articles-listing__article:nth-of-type(3) {
  border-top: 0;
}
.qesheq-web-selector .articles-listing__article:nth-of-type(3n) {
  padding-left: 0px;
  border-left: none;
}
.qesheq-web-selector .articles-listing__article:nth-of-type(3n-2) {
  padding-right: 0px;
}
@media (max-width: 1140px) {
  .qesheq-web-selector .articles-listing__article {
    padding-top: 20px;
  }
}
@media (max-width: 900px) {
  .qesheq-web-selector .articles-listing__article {
    padding-top: 36px;
  }
  .qesheq-web-selector .articles-listing__article:nth-of-type(2n) {
    border-left: 0;
    padding-right: 20px;
    padding-left: 0px;
  }
  .qesheq-web-selector .articles-listing__article:nth-of-type(2n - 1) {
    border-left: 1px solid #ccc;
    padding-right: 0px;
    padding-left: 20px;
  }
  .qesheq-web-selector .articles-listing__article:nth-of-type(3) {
    border-top: 1px solid #ccc;
  }
}
@media (max-width: 725px) {
  .qesheq-web-selector .articles-listing__article {
    padding-top: 20px;
  }
}
@media (max-width: 535px) {
  .qesheq-web-selector .articles-listing__article {
    border-left: none !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .qesheq-web-selector .articles-listing__article:nth-of-type(n + 2) {
    border-top: 1px solid #ccc;
  }
}
.qesheq-web-selector .articles-listing__article:nth-of-type(3n-1) .articles-listing__article-content {
  margin: auto;
}
.qesheq-web-selector .articles-listing__article:nth-of-type(3n) .articles-listing__article-content {
  margin-right: auto;
}
.qesheq-web-selector .articles-listing__article-content {
  max-width: 394px;
  position: relative;
  height: 100%;
}
.qesheq-web-selector .articles-listing__article-content:not(.qesheq-web-selector .articles-listing__article-content--skeleton) {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 535px) {
  .qesheq-web-selector .articles-listing__article-content {
    margin: auto;
  }
}
.qesheq-web-selector .articles-listing__article-content-bottom {
  margin-top: 16px;
  color: #999;
  font-size: 15px;
}
.qesheq-web-selector .articles-listing__article-content-bottom--skeleton {
  display: flex;
}
@media (min-width: 901px) and (max-width: 1140px) {
  .qesheq-web-selector .articles-listing__article-content-bottom {
    font-size: 14px;
  }
}
@media (max-width: 725px) {
  .qesheq-web-selector .articles-listing__article-content-bottom {
    font-size: 14px;
  }
}
.qesheq-web-selector .articles-listing__article-content-bottom :nth-child(n + 2) {
  border-right: 1px solid #aaa;
  padding-right: 12px;
}
@media (min-width: 901px) and (max-width: 1140px) {
  .qesheq-web-selector .articles-listing__article-content-bottom :nth-child(n + 2) {
    padding-right: 8px;
  }
}
@media (max-width: 725px) {
  .qesheq-web-selector .articles-listing__article-content-bottom :nth-child(n + 2) {
    padding-right: 8px;
  }
}
.qesheq-web-selector .articles-listing__article-content-bottom :first-of-type {
  padding-left: 12px;
}
@media (min-width: 901px) and (max-width: 1140px) {
  .qesheq-web-selector .articles-listing__article-content-bottom :first-of-type {
    padding-left: 8px;
  }
}
@media (max-width: 725px) {
  .qesheq-web-selector .articles-listing__article-content-bottom :first-of-type {
    padding-left: 8px;
  }
}
.qesheq-web-selector .articles-listing__article-overlay {
  width: calc(100% + 24px);
  height: calc(100% + 20px);
  position: absolute;
  right: -16px;
  top: -10px;
  background: rgba(244, 244, 244, 0.78);
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
}
.qesheq-web-selector .articles-listing__article-frame {
  max-width: 394px;
  display: block;
  position: relative;
}
.qesheq-web-selector .articles-listing__article-frame .react-loading-skeleton {
  height: 100%;
}
.qesheq-web-selector .articles-listing__article-image {
  aspect-ratio: 100 / 62;
  width: 100%;
  display: block;
}
.qesheq-web-selector .articles-listing__article-category {
  position: absolute;
  bottom: 10px;
  right: -15px;
  padding: 4px 8px;
}
@media (min-width: 536px) and (max-width: 1460px) {
  .qesheq-web-selector .articles-listing__article-category {
    right: -5px;
  }
}
.qesheq-web-selector .articles-listing__article-title {
  font-size: 24px;
  line-height: 30px;
  color: #323335;
  padding-top: 16px;
  display: block;
}
@media (max-width: 1140px) {
  .qesheq-web-selector .articles-listing__article-title {
    font-size: 22px;
    line-height: 27.5px;
  }
}
@media (min-width: 726px) and (max-width: 900px) {
  .qesheq-web-selector .articles-listing__article-title {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 725px) {
  .qesheq-web-selector .articles-listing__article-title {
    padding-top: 12px;
  }
}
@media (max-width: 670px) {
  .qesheq-web-selector .articles-listing__article-title {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (min-width: 421px) and (max-width: 535px) {
  .qesheq-web-selector .articles-listing__article-title {
    font-size: 22px;
    line-height: 27.5px;
  }
}
@media (max-width: 420px) {
  .qesheq-web-selector .articles-listing__article-title {
    padding-top: 8px;
    font-size: 20px;
    line-height: 25px;
  }
}
.qesheq-web-selector .articles-listing__article-description {
  font-size: 16px;
  line-height: 24.8px;
  padding-top: 16px;
  display: block;
  font-family: 'Tajawal', 'GEFlow', sans-serif;
  font-weight: 500;
  color: #5b5c5d;
}
@media (max-width: 1140) {
  .qesheq-web-selector .articles-listing__article-description {
    font-size: 14px;
    line-height: 21.7px;
  }
}
@media (max-width: 900px) {
  .qesheq-web-selector .articles-listing__article-description {
    font-size: 16px;
    line-height: 24.8px;
  }
}
@media (max-width: 725px) {
  .qesheq-web-selector .articles-listing__article-description {
    padding-top: 12px;
    font-size: 14px;
    line-height: 21.7px;
  }
}
@media (max-width: 420px) {
  .qesheq-web-selector .articles-listing__article-description {
    padding-top: 8px;
  }
}
.qesheq-web-selector .articles-listing__article-description .react-loading-skeleton:nth-of-type(2) {
  width: 80%;
}
.qesheq-web-selector .articles-listing__article-date.react-loading-skeleton {
  width: 40px;
}
.qesheq-web-selector .articles-listing__article-writer {
  padding-left: 5px;
  max-width: 94px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 1140px) {
  .qesheq-web-selector .articles-listing__article-writer {
    max-width: 90px;
  }
}
@media (min-width: 901px) and (max-width: 1050px) {
  .qesheq-web-selector .articles-listing__article-writer {
    max-width: 120px;
  }
}
@media (max-width: 725px) {
  .qesheq-web-selector .articles-listing__article-writer {
    max-width: 90px;
  }
}
@media (min-width: 536px) and (max-width: 670px) {
  .qesheq-web-selector .articles-listing__article-writer {
    max-width: 120px;
  }
}
.qesheq-web-selector .articles-listing__article-writer.react-loading-skeleton {
  width: 60px;
}
.qesheq-web-selector .articles-listing__article-share-list {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 2px;
  bottom: 0;
  z-index: 2;
}
.qesheq-web-selector .articles-listing__article-share-list li {
  width: 32px;
  height: 32px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
}
.qesheq-web-selector .articles-listing__article-share-list li a {
  color: white;
}
.qesheq-web-selector .articles-listing__article-share-list li:nth-of-type(n + 2) {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  -webkit-transition: transform 0.2s, opacity 0.1s;
  -moz-transition: transform 0.2s, opacity 0.1s;
  -ms-transition: transform 0.2s, opacity 0.1s;
  -o-transition: transform 0.2s, opacity 0.1s;
}
.qesheq-web-selector .articles-listing__article-share-list li:nth-of-type(2) {
  bottom: 40px;
  transition-delay: 0.16s;
}
.qesheq-web-selector .articles-listing__article-share-list li:nth-of-type(3) {
  bottom: 80px;
  transition-delay: 0.12s;
}
.qesheq-web-selector .articles-listing__article-share-list li:nth-of-type(4) {
  bottom: 120px;
  transition-delay: 80ms;
}
.qesheq-web-selector .articles-listing__article-share-list li:nth-of-type(5) {
  bottom: 160px;
  transition-delay: 40ms;
}
@media (min-width: 769px) {
  .qesheq-web-selector .articles-listing__article-share-list:hover {
    height: 200px;
  }
  .qesheq-web-selector .articles-listing__article-share-list:hover li {
    pointer-events: fill;
    opacity: 1;
    -webkit-transform: none;
  }
  .qesheq-web-selector .articles-listing__article-share-list:hover + .articles-listing__article-overlay {
    opacity: 1;
    pointer-events: fill;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .articles-listing__article-share-list--visible {
    height: 200px;
  }
  .qesheq-web-selector .articles-listing__article-share-list--visible li:nth-of-type(n + 2) {
    pointer-events: fill;
    opacity: 1;
    -webkit-transform: none;
  }
  .qesheq-web-selector .articles-listing__article-share-list--visible + .articles-listing__article-overlay {
    opacity: 1;
    pointer-events: fill;
  }
}
.qesheq-web-selector .articles-listing__share-toggle {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: end;
  z-index: 1;
}
.qesheq-web-selector .articles-listing__share-link {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  color: #fff;
  display: block;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.qesheq-web-selector .articles-listing__share-icon {
  font-size: 22px;
}
.qesheq-web-selector .articles-listing__share-email a {
  background: #797979;
}
.qesheq-web-selector .articles-listing__share-facebook a {
  background: #3b5998;
}
.qesheq-web-selector .articles-listing__share-twitter a {
  background: #00aced;
}
.qesheq-web-selector .articles-listing__share-whatsapp a {
  background: #1db610;
}
.qesheq-web-selector .articles-listing__email-icon {
  font-size: 16px;
}
.qesheq-web-selector .articles-listing__facebook-icon {
  font-size: 15px;
}
.qesheq-web-selector .articles-listing__twitter-icon {
  font-size: 16px !important;
}
.qesheq-web-selector .articles-listing__whatsapp-icon {
  font-size: 16px !important;
}
