.qesheq-web-selector .about-page__body {
  padding-top: 64px;
  padding-bottom: 64px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .about-page__body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.qesheq-web-selector .about-page__description {
  font-size: 20px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .about-page__description {
    font-size: 16px;
  }
}
.qesheq-web-selector .about-page__description-skeleton {
  height: 18px;
  margin-top: 16px;
}
.qesheq-web-selector .about-page__description-skeleton:nth-of-type(2) {
  width: 85%;
}
.qesheq-web-selector .about-page__description-skeleton:nth-of-type(3) {
  width: 55%;
}
.qesheq-web-selector .about-page__description-skeleton:nth-of-type(4) {
  width: 70%;
}
.qesheq-web-selector .about-page__description-skeleton:nth-of-type(5) {
  width: 20%;
  margin-top: 50px;
}
.qesheq-web-selector .about-page__description-skeleton:nth-of-type(6) {
  width: 90%;
}
.qesheq-web-selector .about-page__description-skeleton:nth-of-type(7) {
  width: 75%;
}
.qesheq-web-selector .about-page__description-skeleton:nth-of-type(8) {
  width: 50%;
}
