.qesheq-web-selector .breadcrumbs-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
}
.qesheq-web-selector .breadcrumbs-container li {
  color: #929090;
  font-size: 16px;
}
.qesheq-web-selector .breadcrumbs-container__arrows-seperator {
  font-size: 8px;
  margin: 0 8px 0 6px;
}
