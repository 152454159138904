.qesheq-web-selector .article-head {
  padding-top: 44px;
}
@media (max-wdith: 768px) {
  .qesheq-web-selector .article-head {
    padding-top: 28px;
  }
}
.qesheq-web-selector .article-head__title {
  font-size: 54px;
  display: block;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-head__title {
    font-size: 40px;
  }
}
.qesheq-web-selector .article-head__title-skeleton {
  width: calc(100% - 70px);
  max-width: 500px;
}
.qesheq-web-selector .article-head__info-list {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
@media (max-wdith: 768px) {
  .qesheq-web-selector .article-head__info-list {
    margin-top: 8px;
  }
}
.qesheq-web-selector .article-head__info-list-skeleton {
  width: calc(100% - 100px);
  max-width: 300px;
  height: 32px;
}
.qesheq-web-selector .article-head__info {
  font-size: 14px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 36px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-head__info {
    min-height: 34px;
  }
}
@media (max-width: 400px) {
  .qesheq-web-selector .article-head__info {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .qesheq-web-selector .article-head__category {
    padding: 0 7px;
  }
}
.qesheq-web-selector .article-head__date {
  padding: 0px 16px;
  background: #e6e6e6;
  color: #474749;
}
@media (max-width: 650px) {
  .qesheq-web-selector .article-head__date {
    padding: 0 12px;
  }
}
.qesheq-web-selector .article-head__writer {
  padding: 0 10px;
  background: #dfdfdf;
  color: #474749;
}
.qesheq-web-selector .article-head__views {
  background: #1e1f20;
  padding: 0 48px 0 12px;
  color: #fff;
  position: relative;
  margin-right: 9px;
  font-size: 16px;
  font-family: "Overpass", sans-serif;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-head__views {
    padding: 0 38px 0 10px;
  }
}
@media (max-width: 400px) {
  .qesheq-web-selector .article-head__views {
    font-size: 14px;
  }
}
.qesheq-web-selector .article-head__views-icon {
  font-size: 22px !important;
  position: absolute;
  top: 50%;
  right: 11px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
@media (max-width: 400px) {
  .qesheq-web-selector .article-head__views-icon {
    font-size: 20px !important;
  }
}
