.qesheq-web-selector .categories-listing {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 768px) {
  .qesheq-web-selector .categories-listing {
    grid-template-columns: 1fr;
  }
}
.qesheq-web-selector .listing-category {
  width: 100%;
  position: relative;
}
.qesheq-web-selector .listing-category::before {
  content: '';
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.8)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
.qesheq-web-selector .listing-category__title {
  position: absolute;
  right: 52px;
  bottom: 20px;
  font-size: 52px;
  line-height: 62.4px;
  color: #f8f6f6;
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .qesheq-web-selector .listing-category__title {
    right: 42px;
    font-size: 40px;
    line-height: 48px;
  }
}
@media (max-width: 800px) {
  .qesheq-web-selector .listing-category__title {
    right: 36px;
    font-size: 32px;
    line-height: 38.4px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .listing-category__title {
    right: 30px;
    font-size: 44px;
    line-height: 52.8px;
  }
}
@media (max-width: 480px) {
  .qesheq-web-selector .listing-category__title {
    font-size: 32px;
    line-height: 38.4px;
  }
}
.qesheq-web-selector .listing-category__image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 15123/10000;
  display: block;
}
