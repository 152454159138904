.qesheq-web-selector .become-a-writer-form {
  padding-top: 96px;
  padding-bottom: 64px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .become-a-writer-form {
    padding-top: 64px;
  }
}
.qesheq-web-selector .become-a-writer-form__container {
  max-width: 1000px;
}
.qesheq-web-selector .become-a-writer-form__form {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
@media (max-width: 480px) {
  .qesheq-web-selector .become-a-writer-form__form {
    grid-template-columns: 1fr;
  }
}
.qesheq-web-selector .become-a-writer-form__text-area-field {
  grid-column: span 2;
  margin: auto;
}
@media (max-width: 480px) {
  .qesheq-web-selector .become-a-writer-form__text-area-field {
    grid-column: span 1;
  }
}
.qesheq-web-selector .become-a-writer-form__submit-button {
  grid-column: span 2;
  max-width: 200px;
  margin: auto;
}
@media (max-width: 480px) {
  .qesheq-web-selector .become-a-writer-form__submit-button {
    grid-column: 1;
  }
}
.qesheq-web-selector .become-a-writer-form__error-container,
.qesheq-web-selector .become-a-writer-form__success-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #f8f6f6;
  text-align: center;
  padding-top: 112px;
  opacity: 0;
  pointer-events: none;
  visibility: none;
  -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
  -moz-transition: opacity 0.5s, visibility 0s 0.5s;
  -ms-transition: opacity 0.5s, visibility 0s 0.5s;
  -o-transition: opacity 0.5s, visibility 0s 0.5s;
}
@media (max-width: 768px) {
  .qesheq-web-selector .become-a-writer-form__error-container,
  .qesheq-web-selector .become-a-writer-form__success-container {
    padding-top: 160px;
  }
}
.qesheq-web-selector .become-a-writer-form__error-container--visible,
.qesheq-web-selector .become-a-writer-form__success-container--visible {
  opacity: 1;
  visibility: visible;
  pointer-events: fill;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
}
.qesheq-web-selector .become-a-writer-form__error-title,
.qesheq-web-selector .become-a-writer-form__success-title {
  font-size: 48px;
  display: block;
}
.qesheq-web-selector .become-a-writer-form__error-message,
.qesheq-web-selector .become-a-writer-form__success-message {
  font-size: 24px;
}
