.qesheq-web-selector .hero-section {
  height: 600px;
  position: relative;
  color: #fff;
  font-size: 22px;
}
@media (max-width: 1000px) {
  .qesheq-web-selector .hero-section {
    font-size: 20px;
  }
}
@media (max-width: 890px) {
  .qesheq-web-selector .hero-section {
    font-size: 18px;
  }
}
@media (max-width: 800px) {
  .qesheq-web-selector .hero-section {
    font-size: 20px;
    height: auto;
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
.qesheq-web-selector .hero-section::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  right: 0;
  top: 0;
  z-index: 1;
}
.qesheq-web-selector .hero-section__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.qesheq-web-selector .hero-section__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  z-index: 1;
}
@media (max-width: 800px) {
  .qesheq-web-selector .hero-section__container {
    display: block;
  }
}
.qesheq-web-selector .hero-section__main-article {
  padding-bottom: 112px;
  max-width: 460px;
}
@media (max-width: 1000px) {
  .qesheq-web-selector .hero-section__main-article {
    max-width: 420px;
  }
}
@media (max-width: 890px) {
  .qesheq-web-selector .hero-section__main-article {
    max-width: 400px;
  }
}
@media (max-width: 800px) {
  .qesheq-web-selector .hero-section__main-article {
    padding-bottom: 0px;
  }
}
@media (max-width: 650px) {
  .qesheq-web-selector .hero-section__main-article {
    width: 100%;
    font-size: 16px;
  }
}
.qesheq-web-selector .hero-section__main-article-label {
  font-size: 30px;
  padding: 5px 10px;
}
@media (max-width: 800px) {
  .qesheq-web-selector .hero-section__main-article-label {
    font-size: 22px;
  }
}
.qesheq-web-selector .hero-section__main-article-label-skeleton,
.qesheq-web-selector .hero-section__main-article-link-skeleton {
  display: block;
}
.qesheq-web-selector .hero-section__main-article-label-skeleton {
  width: 80px;
  font-size: 40px;
  height: 45px;
}
@media (max-width: 650px) {
  .qesheq-web-selector .hero-section__main-article-label-skeleton {
    font-size: 32px;
    height: 37px;
  }
}
.qesheq-web-selector .hero-section__main-article-link-skeleton {
  width: 300px;
  font-size: 40px;
  height: 50px;
}
.qesheq-web-selector .hero-section__main-article-link-skeleton .react-loading-skeleton:nth-of-type(2) {
  width: 240px;
}
@media (max-width: 650px) {
  .qesheq-web-selector .hero-section__main-article-link-skeleton .react-loading-skeleton:nth-of-type(2) {
    display: none;
  }
}
@media (max-width: 650px) {
  .qesheq-web-selector .hero-section__main-article-link-skeleton {
    font-size: 32px;
    height: 42px;
  }
}
.qesheq-web-selector .hero-section__main-article-link {
  font-size: 60px;
  display: block;
  margin-top: 10px;
}
@media (max-width: 1000px) {
  .qesheq-web-selector .hero-section__main-article-link {
    font-size: 54px;
  }
}
@media (max-width: 890px) {
  .qesheq-web-selector .hero-section__main-article-link {
    font-size: 48px;
  }
}
@media (max-width: 800px) {
  .qesheq-web-selector .hero-section__main-article-link {
    font-size: 46px;
  }
}
@media (max-width: 650px) {
  .qesheq-web-selector .hero-section__main-article-link {
    font-size: 34px;
  }
}
.qesheq-web-selector .hero-section__small-articles-container {
  height: 100%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 352px;
}
@media (max-width: 890px) {
  .qesheq-web-selector .hero-section__small-articles-container {
    width: 330px;
  }
}
@media (max-width: 800px) {
  .qesheq-web-selector .hero-section__small-articles-container {
    margin-top: 30px;
    width: 352px;
  }
}
@media (max-width: 650px) {
  .qesheq-web-selector .hero-section__small-articles-container {
    max-width: 100%;
  }
}
.qesheq-web-selector .hero-section__small-articles-container li:nth-of-type(n + 2) {
  margin-top: 15px;
}
.qesheq-web-selector .hero-section__small-article {
  background: rgba(0, 0, 0, 0.4);
  padding: 60px 24px 18px;
  position: relative;
  width: 100%;
}
@media (max-width: 650px) {
  .qesheq-web-selector .hero-section__small-article {
    padding-right: 11px;
  }
}
.qesheq-web-selector .hero-section__small-article-label {
  position: absolute;
  right: -7px;
  top: 15px;
}
.qesheq-web-selector .hero-section__small-article-link {
  font-size: 1em;
}
.qesheq-web-selector .hero-section__small-article-label-skeleton,
.qesheq-web-selector .hero-section__small-article-link-skeleton {
  display: block;
}
.qesheq-web-selector .hero-section__small-article-label-skeleton {
  width: 50px;
  position: absolute;
  right: -5px;
  top: 10px;
}
.qesheq-web-selector .hero-section__small-article-link-skeleton {
  width: 220px;
  max-width: 100%;
}
