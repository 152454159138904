.qesheq-web-selector .contact-page__email {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  color: #f2f2f2;
  background: rgba(230, 26, 50, 0.8);
  border-top: 3px solid #e01c33;
  padding: 10px 45px;
  font-family: "Overpass", sans-serif;
  font-weight: 500;
  z-index: 1;
}
