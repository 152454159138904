.qesheq-web-selector .article-sidebar {
  width: 100%;
}
@media (max-width: 950px) {
  .qesheq-web-selector .article-sidebar {
    margin-bottom: 24px;
  }
}
.qesheq-web-selector .article-sidebar__box {
  background: white;
}
.qesheq-web-selector .article-sidebar__box:nth-of-type(n + 2) {
  margin-top: 24px;
}
.qesheq-web-selector .article-sidebar__ad-box {
  width: 100%;
  padding: 28px 20px;
}
.qesheq-web-selector .article-sidebar__ad-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.qesheq-web-selector .article-sidebar__ad {
  width: 250px;
  height: 250px;
  margin: auto;
}
@media (min-width: 360px) {
  .qesheq-web-selector .article-sidebar__ad {
    width: 300px;
    height: 250px;
  }
}
@media (min-width: 396px) {
  .qesheq-web-selector .article-sidebar__ad {
    width: 336px;
    height: 280px;
  }
}
@media (min-width: 528px) {
  .qesheq-web-selector .article-sidebar__ad {
    width: 468px;
    height: 60px;
  }
}
@media (min-width: 789px) {
  .qesheq-web-selector .article-sidebar__ad {
    width: 728px;
    height: 90px;
  }
}
@media (min-width: 951px) {
  .qesheq-web-selector .article-sidebar__ad {
    width: 300px;
    height: auto;
  }
}
.qesheq-web-selector .article-sidebar__related-articles-box {
  padding: 24px 20px 28px;
}
.qesheq-web-selector .article-sidebar__related-articles-title {
  font-size: 30px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-sidebar__related-articles-title {
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  .qesheq-web-selector .article-sidebar__related-articles-title {
    font-size: 26px;
  }
}
.qesheq-web-selector .article-sidebar__article-frame {
  width: 130px;
  min-height: 81px;
  position: relative;
  overflow: hidden;
}
.qesheq-web-selector .article-sidebar__article_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.qesheq-web-selector .article-sidebar__article-content {
  width: calc(100% - 142px);
  padding-bottom: 24px;
  position: relative;
}
.qesheq-web-selector .article-sidebar__article-title {
  font-size: 16px;
  line-height: 17px;
}
.qesheq-web-selector .article-sidebar__article-info {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 13px;
  color: #5b5c5d;
}
.qesheq-web-selector .article-sidebar__article-date {
  padding-left: 8px;
}
.qesheq-web-selector .article-sidebar__article-writer {
  padding-right: 8px;
  position: relative;
}
.qesheq-web-selector .article-sidebar__article-writer::before {
  content: '';
  width: 1px;
  height: 13px;
  background: #aaa8a8;
  position: absolute;
  right: 0;
  top: calc(50% - 7px);
}
.qesheq-web-selector .article-sidebar__article {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 16px;
}
.qesheq-web-selector .article-sidebar__article:first-of-type {
  padding: 12px 0px 24px;
  position: relative;
}
.qesheq-web-selector .article-sidebar__article:first-of-type::after {
  content: '';
  height: 3px;
  background: #f4f2f2;
  width: calc(100% + 40px);
  right: -20px;
  bottom: 0;
  position: absolute;
}
.qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-frame {
  width: 100%;
  display: block;
  aspect-ratio: 324/202;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-frame {
    max-width: 330px;
  }
}
.qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-frame-skeleton-container {
  width: 100%;
}
.qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-frame-link {
  width: 100%;
  display: block;
}
.qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-content {
  width: 100%;
  padding-bottom: 0px;
}
.qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-title {
  font-size: 24px;
  line-height: 33.6px;
  padding: 20px 0px 0px 8px;
  display: block;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-title {
    font-size: 22px;
    line-height: 28.6px;
  }
}
.qesheq-web-selector .article-sidebar__article:first-of-type .article-sidebar__article-info {
  margin-top: 8px;
  position: relative;
}
.qesheq-web-selector .article-sidebar__article:nth-of-type(2) {
  margin-top: 24px;
}
.qesheq-web-selector .article-sidebar__article:nth-of-type(2) .article-sidebar__article-frame {
  aspect-ratio: 130/81;
}
.qesheq-web-selector .article-sidebar__article:nth-of-type(n + 3) {
  margin-top: 40px;
}
.qesheq-web-selector .article-sidebar__related-categories {
  padding: 24px 20px 28px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-sidebar__related-categories {
    padding: 24px 20px 8px;
  }
}
.qesheq-web-selector .article-sidebar__related-categories-title {
  font-size: 30px;
  padding-bottom: 20px;
  display: block;
  position: relative;
}
.qesheq-web-selector .article-sidebar__related-categories-title::after {
  content: '';
  height: 3px;
  background: #f4f2f2;
  width: calc(100% + 40px);
  right: -20px;
  bottom: 0;
  position: absolute;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-sidebar__related-categories-title {
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  .qesheq-web-selector .article-sidebar__related-categories-title {
    font-size: 26px;
  }
}
.qesheq-web-selector .article-sidebar__categories-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 24px 0px 0px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-sidebar__categories-list {
    justify-content: space-between;
  }
}
.qesheq-web-selector .article-sidebar__category {
  width: 100%;
}
.qesheq-web-selector .article-sidebar__category-frame {
  display: block;
  width: min(147px, 100%);
  height: 98px;
}
.qesheq-web-selector .article-sidebar__category-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.qesheq-web-selector .article-sidebar__category-title {
  font-size: 20px;
  display: block;
  padding-top: 4px;
}
