.qesheq-web-selector .footer-layout {
  min-height: 100%;
  position: relative;
  padding-bottom: 244px;
}
@media (max-width: 1055px) {
  .qesheq-web-selector .footer-layout {
    padding-bottom: 370px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout {
    padding-bottom: 100px;
  }
}
.qesheq-web-selector .footer-layout__footer {
  min-height: 244px;
  width: 100%;
  min-width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 9;
  color: #ccc;
  background: #1e1f20;
  padding-top: 60px;
  padding-bottom: 20px;
}
@media (max-width: 1055px) {
  .qesheq-web-selector .footer-layout__footer {
    min-height: 370px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__footer {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 0;
    height: 100px;
  }
}
.qesheq-web-selector .footer-layout__container {
  height: 100%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__container {
    align-items: center;
    height: 100%;
  }
}
.qesheq-web-selector .footer-layout__sitemap {
  columns: 4;
  font-size: 18px;
  width: 52.15%;
  position: relative;
}
@media (max-width: 1055px) {
  .qesheq-web-selector .footer-layout__sitemap {
    columns: 3;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__sitemap {
    display: none;
  }
}
.qesheq-web-selector .footer-layout__sitemap-seperator {
  height: calc(100% - 14px);
  width: 1px;
  background: rgba(102, 102, 102, 0.5);
  position: absolute;
  display: block;
}
.qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(1) {
  right: 21%;
}
.qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(2) {
  right: 46.5%;
}
.qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(3) {
  right: 75%;
}
.qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(4) {
  right: 97%;
}
@media (max-width: 1055px) {
  .qesheq-web-selector .footer-layout__sitemap-seperator {
    height: 100%;
  }
  .qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(1) {
    right: 30%;
  }
  .qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(2) {
    right: 64%;
  }
  .qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(3),
  .qesheq-web-selector .footer-layout__sitemap-seperator:nth-of-type(4) {
    display: none;
  }
}
.qesheq-web-selector .footer-layout__sitemap-link {
  display: block;
  white-space: nowrap;
  margin-bottom: 16px;
}
.qesheq-web-selector .footer-layout__actions {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  width: 47.85%;
}
@media (max-width: 1055px) {
  .qesheq-web-selector .footer-layout__actions {
    display: block;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__actions {
    width: 100%;
  }
}
.qesheq-web-selector .footer-layout__become-a-writer {
  width: 61.11%;
  padding: 16px 0px 0px 8px;
}
@media (max-width: 1055px) {
  .qesheq-web-selector .footer-layout__become-a-writer {
    width: 100%;
    border-right: 0;
    padding-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__become-a-writer {
    display: none;
  }
}
.qesheq-web-selector .footer-layout__become-a-writer-title {
  font-size: 26px;
  display: block;
  text-align: center;
}
.qesheq-web-selector .footer-layout__become-a-writer-button {
  border-radius: 30px;
  max-width: 166px;
  margin: 16px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(154, 154, 154, 0.35);
  font-size: 18px;
  height: 49px;
}
.qesheq-web-selector .footer-layout__social {
  width: 38.89%;
  padding: 16px 12px 0px;
  border-right: 1px solid rgba(102, 102, 102, 0.5);
}
@media (max-width: 1055px) {
  .qesheq-web-selector .footer-layout__social {
    width: 100%;
    border-right: 0;
    position: relative;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__social {
    padding-top: 0px;
  }
}
.qesheq-web-selector .footer-layout__social-title {
  font-size: 26px;
  display: block;
  text-align: center;
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__social-title {
    display: none;
  }
}
.qesheq-web-selector .footer-layout__social-list {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto 0;
  margin: 20px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  max-width: 192px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .footer-layout__social-list {
    margin-top: 0;
  }
}
.qesheq-web-selector .footer-layout__social-list li {
  position: relative;
}
.qesheq-web-selector .footer-layout__social-icon {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgba(154, 154, 154, 0.35);
  border-radius: 100%;
}
.qesheq-web-selector .footer-layout__social-icon svg {
  font-size: 22px;
}
