@font-face {
  font-family: 'GEFlow';
  src: url('../public/fonts/GEFlow.eot');
  src: url('../public/fonts/GEFlow.eot?#iefix') format('embedded-opentype'), url('../public/fonts/GEFlow.woff2') format('woff2'), url('../public/fonts/GEFlow.woff') format('woff'), url('../public/fonts/GEFlow.ttf') format('truetype'), url('../public/fonts/GEFlow.svg#GEFlow') format('svg');
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 10px;
  direction: rtl;
}
body {
  font-family: 'GEFlow', sans-serif, 'Overpass', sans-serif;
  background: #f8f6f6;
  text-align: right;
}
.centered-container {
  max-width: 1420px;
}
.page-padding {
  padding-left: 16px;
  padding-right: 16px;
}
@media (max-width: 769px) {
  .page-padding {
    padding-left: 8px;
    padding-right: 8px;
  }
}
#root {
  height: 100%;
}
.main-container {
  width: 100%;
  height: 100%;
}
