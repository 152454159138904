.qesheq-web-selector .article-details-page__container {
  display: grid;
  grid-template-columns: 1fr 364px;
  margin-top: 36px;
  grid-column-gap: 26px;
}
@media (max-width: 950px) {
  .qesheq-web-selector .article-details-page__container {
    display: block;
  }
}
.qesheq-web-selector .article-details-page__listing {
  margin-top: 64px;
  margin-bottom: 64px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .article-details-page__listing {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
