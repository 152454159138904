.qesheq-web-selector .categories-page {
  padding-top: 31px;
  padding-bottom: 80px;
}
.qesheq-web-selector .categories-page__title {
  font-size: 54px;
  color: #323335;
}
.qesheq-web-selector .categories-page__breadcrumbs {
  margin-top: -12px;
}
.qesheq-web-selector .categories-page__listing {
  margin: 28px 0px 0px;
}
