.qesheq-web-selector .writer-hero__banner {
  height: 280px;
  position: relative;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__banner {
    align-items: center;
    justify-content: space-around;
  }
}
.qesheq-web-selector .writer-hero__banner--bottom-margin {
  margin-bottom: 48px;
}
.qesheq-web-selector .writer-hero__banner::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.85));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.85));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.85));
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.85)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.85));
  z-index: 1;
}
.qesheq-web-selector .writer-hero__banner-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.qesheq-web-selector .writer-hero__content {
  color: #fff;
  padding: 0 200px 20px 0;
  width: 100%;
  z-index: 2;
  margin: initial;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 1100px) {
  .qesheq-web-selector .writer-hero__content {
    padding-right: 172px;
  }
}
@media (max-width: 950px) {
  .qesheq-web-selector .writer-hero__content {
    padding-right: 155px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__content {
    padding: 0;
    max-width: 500px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}
.qesheq-web-selector .writer-hero__profile-frame {
  width: 157px;
  height: 157px;
  border-radius: 100%;
  overflow: hidden;
  bottom: -73px;
  right: -5px;
  position: absolute;
}
@media (max-width: 1100px) {
  .qesheq-web-selector .writer-hero__profile-frame {
    width: 130px;
    height: 130px;
    bottom: -56px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__profile-frame {
    position: relative;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 120px;
    height: 120px;
  }
}
.qesheq-web-selector .writer-hero__profile-frame::before {
  content: '';
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  border: 8px solid #fff;
  border-radius: 100%;
  z-index: 1;
  top: 0;
  right: 0;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__profile-frame::before {
    border-width: 5px;
  }
}
.qesheq-web-selector .writer-hero__profile-image {
  position: absolute;
  top: 2px;
  right: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  object-fit: cover;
  border-radius: 100%;
}
.qesheq-web-selector .writer-hero__fullname {
  font-size: 40px;
}
@media (max-width: 1100px) {
  .qesheq-web-selector .writer-hero__fullname {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__fullname {
    text-align: center;
    display: block;
    margin-top: 5px;
  }
}
.qesheq-web-selector .writer-hero__contacts {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__contacts {
    margin-top: 10px;
  }
}
.qesheq-web-selector .writer-hero__contact-link {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background: rgba(244, 244, 244, 0.25);
  background-color: rgba(244, 244, 244, 0.25);
  height: 50px;
  width: 50px;
  font-family: "Overpass", sans-serif;
  border-radius: 30px;
}
.qesheq-web-selector .writer-hero__contact-link:nth-of-type(n + 2) {
  margin-right: 16px;
}
.qesheq-web-selector .writer-hero__contact-link svg {
  font-size: 22px;
}
.qesheq-web-selector .writer-hero__website {
  font-size: 16px;
  width: auto;
  padding: 0 25px;
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__website {
    width: 50px;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__website-text {
    display: none;
  }
}
@media (min-width: 769px) {
  .qesheq-web-selector .writer-hero__website-icon {
    display: none !important;
  }
}
.qesheq-web-selector .writer-hero__description {
  background-color: #f3f0f0;
}
.qesheq-web-selector .writer-hero__description-container {
  display: block;
  padding: 38px 200px 40px 30px;
}
@media (max-width: 1600px) {
  .qesheq-web-selector .writer-hero__description-container {
    padding-left: 0;
  }
}
@media (max-width: 1100px) {
  .qesheq-web-selector .writer-hero__description-container {
    padding-right: 172px;
    padding-top: 33px;
    padding-bottom: 35px;
  }
}
@media (max-width: 950px) {
  .qesheq-web-selector .writer-hero__description-container {
    padding-right: 155px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__description-container {
    padding: 25px 5px;
  }
}
.qesheq-web-selector .writer-hero__bio-editor * {
  font-family: "GEFlow", sans-serif;
}
.qesheq-web-selector .writer-hero__bio-editor p {
  color: #545454;
  font-size: 17px;
  line-height: 36px;
}
@media (max-width: 1100px) {
  .qesheq-web-selector .writer-hero__bio-editor p {
    font-size: 16px;
    line-height: 34px;
  }
}
@media (max-width: 768px) {
  .qesheq-web-selector .writer-hero__bio-editor p {
    font-size: 15px;
    line-height: 32px;
  }
}
