.qesheq-web-selector .mini-hero {
  width: 100%;
  height: 300px;
  position: relative;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 768px) {
  .qesheq-web-selector .mini-hero {
    height: 240px;
  }
}
.qesheq-web-selector .mini-hero::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.qesheq-web-selector .mini-hero__image {
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.qesheq-web-selector .mini-hero__content {
  color: #f5f3f3;
  z-index: 2;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.qesheq-web-selector .mini-hero__title {
  font-size: 80px;
  display: block;
}
.qesheq-web-selector .mini-hero__breadcrumbs li {
  color: #f5f3f3;
  font-size: 18px;
}
